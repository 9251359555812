import { useEffect } from 'react';
import logo from '../../logo.svg';
import './header.scss';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function onClickLogo() {
	window.open(`/`, '_self');
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	});
}

function clearStyle() {
	let classList = document.getElementsByClassName('nav-active');
	for (const item in classList) {
		if (Object.hasOwnProperty.call(classList, item)) {
			const element = classList[item];
			element.classList.remove('nav-active');
		}
	}
}

export default function Header() {
	let navigate = useNavigate();
	const [pageName, setPageName] = useState('');
	const [headerStyle, setHeaderStyle] = useState({
		background: '#f8fafb',
		color: '#242424',
	});

	/* 
		滚动指定位置
		获取div到顶部的位置
	  */
	const onClickScroll = (e) => {
		// 清楚导航栏其他样式
		clearStyle();
		const pageName = e.currentTarget.getAttribute('name');
		const domNav = document.getElementById(pageName + '-nav');
		domNav.className = 'nav-active';
		setPageName(pageName);
		if (window.location.pathname !== '/') {
			navigate(`/?page=${pageName}`);
		} else {
			const dom = document.getElementById(pageName);
			window.scrollTo({
				top: dom.offsetTop - 80,
				behavior: 'smooth'
			});
		}
	};

	const onClickLink = (e) => {
		const path = window.location.pathname;
		console.warn('🚀 ~ onClickLink ~ path:', path);
			// 清楚导航栏其他样式
			const pageName = e.currentTarget.getAttribute('name');
			console.warn(pageName);
			if (pageName === 'platform') {
				// navigate(`platform`);
				window.open('/platform');
			} else {
				clearStyle();
				const domNav = document.getElementById(pageName + '-nav');
				domNav.className = 'nav-active';
			}
	};

	// 窗口滚动改变header样式
	const onWindowScroll = (e) => {
		// window.open(`/`, '_blank');
		// window.location.href('/');
		// const top = document.documentElement.scrollTop;
		// const blackStyle = () => setHeaderStyle({
		// 	background: '#242424',
		// 	color: '#fcfcfc',
		// });
		// const whiteStyle = () => setHeaderStyle({
		// 	background: '#fcfcfc',
		// 	color: '#242424',
		// });

		// if (top === 0) {
		// 	whiteStyle();
		// }
		// if (top >= 600) {
		// 	blackStyle();
		// }
		// if (top >= 1200) {
		// whiteStyle();
		// document.getElementsByClassName('');
		// }
		// if (top >= 1800) {
		// 	blackStyle();
		// }
	};
	useEffect(() => {
		if (pageName !== '') {
			const dom = document.getElementById(pageName);
			window.scrollTo({
				top: dom.offsetTop - 80,
				behavior: 'smooth'
			});
		}
		// window.addEventListener('scroll', onWindowScroll);
		// return () => window.removeEventListener('scroll', onWindowScroll);
	}, [pageName]);

	return (
		<>
			{/* style={headerStyle} */}
			<div className='header-container' >
				<div className='container'>
					<div className={`header`}>
						<div className='logo' onClick={onClickLogo}>
							<img src={logo} className="App-logo" alt="logo" />
							<h2>易道助邦</h2>
						</div>
						<div className='nav'>
							<ul>
								<li onClick={(e) => onClickScroll(e)} name='home'><h4 id='home-nav' className='nav-active'>首页</h4></li>
								<li onClick={(e) => onClickScroll(e)} name='products'><h4 id='products-nav'>产品介绍</h4></li>
								<li onClick={(e) => onClickScroll(e)} name='news'><h4 id='news-nav'>新闻公告</h4></li>
								<li onClick={(e) => onClickScroll(e)} name='about'><h4 id='about-nav'>关于我们</h4></li>
								<li onClick={(e) => onClickLink(e)} name='platform'><h4 id='platform-nav'>平台入口</h4></li>
								{/* <Link to='/platform' target='_blank'></Link> */}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
