import React, { Component } from 'react';
import Footer from './modules/footer';
import Header from './modules/header';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

export default class Layout extends Component {
	render() {
		return (
			<>
				<Header />
				<Routes>
					{this.props.children}
				</Routes>
				<Footer />
			</>
		);
	}
}
